import { Loader } from "../../../../MainComponents";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import React, { useState } from "react";
import SingleChapter from "../../../../MainComponents/SingleChapter";
export default function AdminThreed() {

  const [reFetch, setReFetch] = useState(false);
  const [stage, setStage] = useState(null);

  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  return (
    <section
      className={`h-full  w-full  px-20 py-20 md:px-5 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="3D | Nawar Academy"></HelmetTags>{" "}
      <div className="mb-20 flex flex-col items-end gap-20 capitalize">
        <h2 className="w-full text-4xl font-bold md:text-center">3D</h2>
      </div>
      <div className="mb-32 flex w-full gap-10 md:justify-evenly">
        <button
          onClick={() => setStage(1)}
          className={`mr-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 ${
            stage === 1 && "bg-secondary !text-light"
          }`}
        >
          Stage 1
        </button>
        <button
          onClick={() => setStage(2)}
          className={`mr-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 ${
            stage === 2 && "bg-secondary !text-light"
          }`}
        >
          Stage 2
        </button>
        <button
          onClick={() => setStage(3)}
          className={`mr-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 ${
            stage === 3 && "bg-secondary !text-light"
          }`}
        >
          Stage 3
        </button>
      </div>
      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit mb-[50px]">
          {allLecturesData?.data?.length === 0 ? (
            <p>No lectures</p>
          ) : (
            allLecturesData?.data
              ?.filter((lect) => +lect.is_course === 0)
              ?.filter((lect) => +lect.stage === stage)
              ?.filter((chap) => chap.is_chapter === 1)

              .map((Card) => {
                return (
                  <SingleChapter
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    threed
                    chapter={Card}
                    key={Card.key}
                  />
                );
              })
          )}
        </div>
      )}
    </section>
  );
}
