import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Whatsapp = () => {
  const AuthState = useAuthState();
  const [stage, setStage] = useState("");
  const [attendance, setAttendance] = useState("");
  const [center, setCenter] = useState("");
  const [message, setMesaage] = useState("");
  const [messageTo, setMessageTo] = useState("");
  const [url, setUrl] = useState("");
  const [studentType, setStudentType] = useState("");
  const [caption, setCaption] = useState("");

  const [sendMessage, setSendMessage] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  let fullMessage = {
    link: message !== "" ? null : url,
    caption,
    message: url !== "" ? null : message,
    stage: stage,
    send_to: messageTo,
    attendance_location: attendance,
    status: studentType,
    is_text: message !== "" ? 1 : 0,
  };

  if (attendance === "0") {
    fullMessage = {
      ...fullMessage,
      group_id: center,
    };
  }

  const [allCenters] = useAxios(
    process.env.REACT_APP_ATTENDANCE_LOCATION_API,
    "GET",
    "false"
  );

  const [
    sendMessageToWhatsappSucsses,
    sendMessageErrors,
    sendMessageToWhatsappLoading,
  ] = useAxios(
    process.env.REACT_APP_WHATSAPP_SENDING_MESSAGE_API,
    "POST",
    sendMessage.flag,
    sendMessage.dependency,
    sendMessage.data
  );

  const filterdCenters = allCenters?.groups?.filter(
    (center) => center.stage === Number(stage)
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    setSendMessage({
      flag: "sendMessageToWhatsapp",
      dependency: !sendMessage.dependency,
      data: fullMessage,
    });
  };

  useEffect(() => {
    if (sendMessageToWhatsappSucsses) {
      toast.success(sendMessageToWhatsappSucsses?.message);
    }
  }, [sendMessageToWhatsappSucsses]);

  useEffect(() => {
    if (sendMessageErrors) {
      toast.error(sendMessageErrors?.response?.data.message);
    }
  }, [sendMessageErrors]);

  return (
    <>
      <HelmetTags title="WhatsApp | Nawar Academy"></HelmetTags>
      <div className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
        <h2 className="w-full  text-4xl font-bold md:text-center mb-12">
          WhatsApp
        </h2>
        <form
          onSubmit={handleSubmit}
          className="flex w-3/4 md:w-full mx-auto flex-col items-start  justify-center gap-6 rounded-3xl   p-10 shadow-lg shadow-blue/50 my-6"
        >
          <h2 className="mb-8 w-full text-center">
            Please enter the text you want to send
          </h2>
          <textarea
            name="message"
            value={message}
            disabled={url !== "" || caption !== ""}
            onChange={(e) => setMesaage(e.target.value)}
            placeholder="Enter message"
            className="p-4 w-full h-[120px]"
          />
          <div className="w-full flex justify-center items-center gap-4 md:flex-col-reverse">
            <div className="w-1/2 md:w-full">
              <label
                className="w-full truncate  font-semibold"
                htmlFor="student"
              >
                Student Type
              </label>
              <select
                className=""
                value={studentType}
                onChange={(e) => setStudentType(e.target.value)}
                name="student"
                id="student"
              >
                <option hidden disabled value="">
                  Student Type
                </option>
                <option value="0">Active Students</option>
                <option value="1">Belocked Students</option>
                <option value="2">Out Students</option>
              </select>
            </div>
          </div>

          <div className="w-full flex justify-center items-center gap-4 md:flex-col-reverse">
            <div className="w-1/2 md:w-full">
              <label
                className="w-full truncate  font-semibold"
                htmlFor="caption"
              >
                Caption
              </label>
              <input
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                disabled={message !== ""}
                className="signin-inputs signin-input w-full pl-4 disabled:opacity-50 disabled:cursor-not-allowed"
                type="caption"
                name="caption"
                id="caption"
                placeholder="Caption"
              />
            </div>

            <div className="w-1/2 md:w-full">
              <label className="w-full truncate  font-semibold" htmlFor="url">
                Link
              </label>
              <input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                disabled={message !== ""}
                className="signin-inputs signin-input w-full pl-4 disabled:opacity-50 disabled:cursor-not-allowed"
                type="url"
                name="url"
                id="url"
                placeholder="https://example.com"
              />
            </div>
          </div>

          <div className="flex w-full items-start justify-end md:justify-evenly gap-10 md:gap-6 flex-col mt-5">
            <div className="w-full flex justify-center items-center gap-4 md:flex-col">
              <div className="w-1/2 md:w-full order-1">
                <label
                  className="w-full truncate  font-semibold"
                  htmlFor="stage"
                >
                  Class
                </label>
                <select
                  className=""
                  value={stage}
                  onChange={(e) => setStage(e.target.value)}
                  name="stage"
                  id="stage"
                >
                  <option hidden disabled value="">
                    Class
                  </option>
                  <option value="1">First</option>
                  <option value="2">Second</option>
                  <option value="3">Third</option>
                </select>
              </div>
              <div className="w-1/2  md:w-full order-2">
                <label
                  className="w-full truncate  font-semibold"
                  htmlFor="attendance"
                >
                  Attendance
                </label>
                <select
                  className=""
                  value={attendance}
                  onChange={(e) => setAttendance(e.target.value)}
                  name="attendance"
                  id="attendance"
                >
                  <option hidden disabled value="">
                    Attendance
                  </option>
                  <option value="1">Online</option>
                  <option value="0">Center</option>
                  <option value="2">All</option>
                </select>
              </div>
            </div>

            <div className="w-full flex justify-center items-center gap-4 md:flex-col">
              <div
                className={`w-1/2 md:w-full order-4 ${
                  attendance === "0" ? "visible" : "invisible"
                }`}
              >
                <label
                  className="w-full truncate  font-semibold"
                  htmlFor="center"
                >
                  Center
                </label>
                <select
                  className=""
                  value={center}
                  onChange={(e) => setCenter(e.target.value)}
                  name="center"
                  id="center"
                >
                  {filterdCenters?.map((center) => (
                    <option
                      key={center.n}
                      value={center.key}
                      onChange={(e) => setCenter(e.target.value)}
                    >
                      {center.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="w-1/2 md:w-full order-3">
                <label
                  className="w-full truncate  font-semibold"
                  htmlFor="message"
                >
                  Message to
                </label>
                <select
                  className=""
                  value={messageTo}
                  onChange={(e) => setMessageTo(e.target.value)}
                  name="message"
                  id="message"
                >
                  <option hidden disabled value="">
                    Message To
                  </option>
                  <option value="1">Student</option>
                  <option value="2">Parent 1</option>
                  <option value="3">Parent 2</option>
                </select>
              </div>
            </div>
          </div>
          <div className="w-full">
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("whatsapp_send")
              )) && (
              <button
                type="submit"
                disabled={sendMessageToWhatsappLoading}
                className=" submit mt-10"
              >
                {sendMessageToWhatsappLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Send message"
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default Whatsapp;
