import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useAuthDispatch } from "../../../MainComponents/GlobalContext.jsx";
import axios from "axios";
import { toast } from "react-hot-toast";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LogoLogin from "../../../assets/logo-login.png";
import TeacherImage from "../../../assets/teacher-login.png";

function SignIn({ admin }) {
  const AuthDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [hide, setHide] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [forgetPassowrdLoading, setForgetPassowrdLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const handleSignIn = (data) => {
    setSubmitLoading(true);
    async function postData() {
      try {
        let res = await axios.post(
          admin
            ? process.env.REACT_APP_ADMIN_LOGIN_API
            : process.env.REACT_APP_LOGIN_API,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        let userData = await res.data;
        setSubmitLoading(false);
        localStorage.setItem("userData", JSON.stringify(userData));
        AuthDispatch({ type: "setUserData", payload: userData });

        // set axios
        axios.defaults.headers.common["Authorization"] = JSON.parse(
          localStorage.userData
        ).student_token;

        toast.success(userData?.message);
        reset();
        navigate(
          `${
            admin
              ? "/admin/dashboard"
              : res.data.student.is_student === 0
                ? "/courses"
                : "/home"
          }`,
          { replace: true }
        );
      } catch (error) {
        setSubmitLoading(false);
        setServerErrors(error?.response?.data?.error);
        toast.error(
          error?.response?.data?.error || "An error occurred, please try again."
        );
      }
    }
    postData();
  };

  const handleForgotPassword = (data) => {
    setForgetPassowrdLoading(true);
    //!  if success
    setForgotPassword(false);
    setForgetPassowrdLoading(false);
    //!  if error
    setForgetPassowrdLoading(false);
    setServerErrors();

    reset();
  };

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-24 py-10 dark:bg-dark sm:py-14">
      <HelmetTags
        title="Log in | Nawar Academy"
        description="Log in | Nawar Academy"
        index
      >
        <link rel="canonical" href="https://nawaracademy.com/signin" />
      </HelmetTags>

      <div
        //!welcome-message
        className="welcome-message  flex flex-col items-center  justify-center"
      >
        <Link className="h-[100px] w-auto text-4xl " to="/">
          <img
            className="h-full w-full max-w-[200px] "
            src={LogoLogin}
            alt="logo"
          />
        </Link>
      </div>

      <div className="flex w-1/2 md:w-[90%] lg:w-3/4  shadow-lg shadow-blue-200/50 sm:flex-col">
        <div className="flex-1 w-1/2  flex sm:w-full flex-col items-center ">
          <form
            //!form
            onSubmit={handleSubmit(handleSignIn)}
            method="post"
            className="w-full  flex-col items-start  justify-center p-10"
          >
            {admin && (
              <>
                {/**
                 * //!Username filed
                 *
                 */}
                <div className=" flex w-full flex-col items-end justify-center gap-2">
                  <label htmlFor="username">User name</label>
                  <input
                    className=" signin-inputs signin-input max-w-60 "
                    type="text"
                    id="username"
                    placeholder="User name"
                    name="username"
                    autoComplete="on"
                    {...register("username", {
                      required: true,
                      pattern: /^[A-Za-z0-9-_]*$/,
                      maxLength: 20,
                      minLength: 3,
                    })}
                  />
                  {errors.username && (
                    <p className="text-[12px] text-blue-900 ">
                      {errors.username.type === "required" &&
                        "Please enter your username or email"}
                      {errors.username.type === "pattern" &&
                        "Only letters and numbers are allowed"}
                      {errors.username.type === "maxLength" &&
                        "The maximum number of characters is 20 characters"}
                      {errors.username.type === "minLength" &&
                        "The minimum number of letters is 3 letters"}
                    </p>
                  )}
                </div>
              </>
            )}

            {!admin && (
              <div className={`w-full md:w-full`}>
                <div className=" flex w-full flex-col items-end justify-center gap-2">
                  <label htmlFor="email">email</label>
                  <input
                    className=" signin-inputs signin-input max-w-60 "
                    type="email"
                    id="email"
                    placeholder="Email"
                    name="email"
                    autoComplete="on"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                      maxLength: 254, // Adjusted to a common maximum length for email addresses
                      minLength: 3,
                    })}
                  />
                  {errors.email && (
                    <p className="text-[12px] text-blue-900 ">
                      {errors.email.type === "required" &&
                        "Please enter your email or email"}
                      {errors.email.type === "pattern" &&
                        "Only letters and numbers are allowed"}
                      {errors.email.type === "maxLength" &&
                        "The maximum number of characters is 20 characters"}
                      {errors.email.type === "minLength" &&
                        "The minimum number of letters is 3 letters"}
                    </p>
                  )}
                </div>

                {
                  //!-------server errors -----

                  errors.errors && errors.errors.email && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.errors.email[0]}
                    </p>
                  )
                }
                {
                  //!-------Editing server errors -----

                  errors?.response?.data?.errors &&
                    errors?.response?.data?.errors?.email && (
                      <p className="w-full text-start text-[12px] text-blue-900  ">
                        {errors?.response?.data.errors.email[0]}
                      </p>
                    )
                }
              </div>
            )}

            {/**
             * //!Password filed
             *  */}

            <div className=" mb-4 mt-10 flex w-full flex-col items-end justify-center gap-2">
              <label htmlFor="password">Password</label>
              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password"
                  className=" signin-inputs signin-input max-w-60 "
                  type={`${hide ? "text" : "password"}`}
                  placeholder="Password"
                  autoComplete="on"
                  name="password"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />
                <div
                  //!eye icons
                  onClick={() => setHide(!hide)}
                  className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {hide ? (
                    <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="h-full w-full"
                      icon={faEyeSlash}
                    />
                  )}
                </div>
              </div>
              {errors.password && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.password.type === "required" &&
                    "Please enter your password"}
                  {errors.password.type === "maxLength" &&
                    "The maximum number of characters is 25 characters"}
                  {errors.password.type === "minLength" &&
                    "The minimum number of letters is 6 letters"}
                </p>
              )}

              {
                //!--- server errors --------
                serverErrors && (
                  <p className="text-[12px] text-blue-900 ">{serverErrors}</p>
                )
              }
            </div>

            <div className="flex w-full justify-end  ">
              <Link to="/forget-password">
                <span className="w-fit cursor-pointer text-[13px] underline">
                  Forgot your password?
                </span>
              </Link>
            </div>

            <button
              disabled={!isValid || submitLoading}
              className=" submit submit-login !bg-secondary !rounded-[50px] !h-[50px] max-w-60 mt-10 disabled:opacity-70 "
              type="submit"
            >
              {submitLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Sign in"
              )}
            </button>
          </form>
          {!admin && (
            <div className="my-10 w-full text-center text-[14px] ">
              Don't have an account?
              <Link className="font-bold" to="/register">
                Create account now
              </Link>
            </div>
          )}
        </div>

        <div className="flex-1 flex sm:w-full items-center flex-col gap-10  w-1/2 min-h-[400px] bg-[#00c1ff] py-24">
          <img src={TeacherImage} alt="" className="h-[180px]" />
          <h1 className="text-5xl text-white text-center font-bold">
            Welcome to <br /> Nawar Academy
          </h1>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
