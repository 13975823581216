import { useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Loader } from "../../../MainComponents";

export default function SingleThreeD() {
  const { threedID } = useParams();
  const [ThreedInfo, ThreedInfoErrors, ThreedLoading] = useAxios(
    `${process.env.REACT_APP_GET_SINGLE_THREED_API}${threedID}`,
    "GET",
    "GET",
    ""
  );

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="3D | Nawar Academy"></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center">3D Models</h2>

      {/* 3D Model */}
      {ThreedLoading ? (
        <Loader />
      ) : (
        <div class="sketchfab-embed-wrapper py-10 w-full flex justify-center items-center ">
          {" "}
          <iframe
            title={ThreedInfo?.data?.name}
            frameborder="0"
            allowfullscreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            allow="autoplay; fullscreen; xr-spatial-tracking"
            xr-spatial-tracking
            execution-while-out-of-viewport
            execution-while-not-rendered
            web-share
            src={`${ThreedInfo?.data?.link}/embed`}
            className="max-w-[700px] w-full h-[500px]"
          ></iframe>
        </div>
      )}
    </section>
  );
}
