import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddEditThreed({ edit }) {
  const [values, setValues] = useState(null);
  const [content, setContent] = useState("");
  const { threedID, chapterID } = useParams();
  const navigate = useNavigate();

  function reducer(state, action) {
    switch (action.type) {
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setSubmitAddEditThreed": {
        return {
          ...state,
          submitAddEditThreed: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditThreed: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_THREED_EDIT_API
    : process.env.REACT_APP_ADMIN_THREED_ADD_API;
  //!--------- add edit Threed -------

  const [ThreedAddEditSuccess, ThreedAddEditErrors, ThreedAddEditLoading] =
    useAxios(
      api,
      "POST",
      state.submitAddEditThreed.flag,
      state.submitAddEditThreed.dependency,
      state.submitAddEditThreed.data,
      true
    );

  //!--------- get the Threed info for editing -------

  const [ThreedInfo, ThreedInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_SINGLE_THREED_GET_API}${threedID}`,
    "GET",
    threedID,
    threedID
  );
  useEffect(() => {
    if (ThreedInfo) {
      let temp = ThreedInfo.data;
      delete temp.img;
      setValues({
        ...temp,
      });
    }
  }, [ThreedInfo]);

  useEffect(() => {
    //!---add actions ----

    if (ThreedAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ThreedAddEditSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  const onSubmit = (data) => {
    const finalData = threedID
      ? {
          ...data,
          id: threedID,
          img: data?.img[0] || null,
        }
      : {
          ...data,
          chapter_id: chapterID,
          description: content,
          img: data?.img[0] || null,
        };
    dispatch({
      type: "setSubmitAddEditThreed",
      payload: {
        flag: "AddEditThreed",
        dependency: !state.submitAddEditThreed.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="3D | Nawar Academy"></HelmetTags>

      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {threedID
            ? "Modify class information"
            : "Please fill in the information to add the 3D"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl   p-16 shadow-lg shadow-blue-200/50"
        >
          {/** Name stage filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs signin-input w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                ThreedAddEditErrors &&
                  ThreedAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ThreedAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Link</label>
              <input
                className="signin-inputs signin-input w-full pl-4    "
                type="text"
                id="name"
                placeholder="link"
                name="link"
                autoComplete="on"
                {...register("link", {
                  required: true,
                })}
              />
              {errors.link && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {errors.link.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}

              {
                //!-------server errors -----

                ThreedAddEditErrors &&
                  ThreedAddEditErrors?.response?.data?.errors?.link && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ThreedAddEditErrors?.response?.data?.errors?.link[0]}
                    </p>
                  )
              }
            </div>
          </div>

          {edit && (
            <div className="flex  w-full items-start justify-end gap-16 md:flex-col md:gap-10 ">
              <div className=" visibility flex w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className="text-[12px] text-white ">
                    {errors.visibility.type === "required" &&
                      "برجاء ملئ هذا الحقل"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ThreedAddEditErrors &&
                    ThreedAddEditErrors?.response?.data?.errors?.visibility && (
                      <p className="w-full text-end text-[12px] text-white  ">
                        {
                          ThreedAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** course Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full" htmlFor="img">
              Upload image
            </label>

            <input
              id="img"
              className="signin-inputs signin-input   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full  text-[12px] text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a picture of the class"}
                {errors.img.type === "validate" && "Maximum image size is 5MB"}
              </p>
            )}
            {
              //!-------server errors -----

              ThreedAddEditErrors &&
                ThreedAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {ThreedAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>

          {/** submit */}

          <button
            disabled={
              !isValid ||
              (ThreedAddEditLoading && state.submitAddEditThreed.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {ThreedAddEditLoading && state.submitAddEditThreed.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p> {threedID ? "Confirm Edit" : "Add Threed"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}
