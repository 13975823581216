import { useEffect, useReducer, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
// import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../MainComponents/Hooks/useAxios";
// import logoLight from "../../../assets/logo-light.png";
import LogoLogin from "../../../assets/logo-login.png";
import LogoLoginCourse from "../../../assets/register-logo-2.png";
// import TeacherImage from "../../../assets/teacher.png";

import Loader from "../../../MainComponents/Loader";
import StudentForm from "./StudentForm";
import CourseStudentForm from "./CourseStudentForm";

function Register({ edit, StudentInfo, studentInfoLoading }) {
  const [values, setValues] = useState(null);
  const [governorateLoading, setGovernorateLoading] = useState(false);
  const [formType, setFormType] = useState("student");
  const { studentID } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values,
  });

  const watchCountries = watch("countries");
  const watchGovernorate = watch("governorate");
  const watchArea = watch("area");

  const watchLevel = watch("stage");
  const watchPlace = watch("is_online");
  const watchActivationCode = watch("activation_code_radio");

  function reducer(state, action) {
    switch (action.type) {
      case "setStates": {
        return {
          ...state,
          states: action.payload,
        };
      }
      case "setGroups": {
        return {
          ...state,
          groups: action.payload,
        };
      }
      case "setSelectedState": {
        return {
          ...state,
          selectedState: action.payload,
        };
      }
      case "setCitySelected": {
        return {
          ...state,
          citySelected: action.payload,
        };
      }
      case "setGovernorate": {
        return {
          ...state,
          governorate: action.payload,
        };
      }
      case "setCities": {
        return {
          ...state,
          cities: action.payload,
        };
      }
      case "setErrors": {
        return {
          ...state,
          errors: action.payload,
        };
      }

      case "setHide": {
        return {
          ...state,
          hide: !state.hide,
        };
      }
      case "setSubmitLoading": {
        return {
          ...state,
          submitLoading: action.payload,
        };
      }
      case "setSubmitEditStudent": {
        return {
          ...state,
          submitEditStudent: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    hide: false,
    states: [],
    governorate: [],
    cities: [],
    groups: [],
    selectedState: "",
    selectedCity: "",
    errors: {},
    submitLoading: false,
    submitEditStudent: { flag: "", dependency: false, data: {} },
  });

  useEffect(() => {
    const citySelector = document.querySelector(".city-selector");

    citySelector?.addEventListener("change", function handleStateSelected(e) {
      dispatch({
        type: "setCitySelected",
        payload: e.target.selectedIndex,
      });
    });
    async function fetchData() {
      setGovernorateLoading(true);

      try {
        const res = await axios(process.env.REACT_APP_GOVERNORATES_API);
        const data = await res.data;
        dispatch({
          type: "setStates",
          payload: data?.states,
        });
        dispatch({
          type: "setGroups",
          payload: data?.groups,
        });
      } catch (error) {
        toast.error(
          "An error occurred while loading cities. Please try again."
        );
      } finally {
        setGovernorateLoading(false);
      }
    }
    fetchData();
  }, []);

  const [StudentEditSuccess, StudentEditErrors, StudentEditLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_INFO_API,
    "POST",
    state.submitEditStudent.flag,
    state.submitEditStudent.dependency,
    state.submitEditStudent.data,
    true
  );

  useEffect(() => {
    if (StudentEditSuccess) {
      reset();
      navigate(-1);
    }
  }, [StudentEditSuccess]);

  useEffect(() => {
    if (StudentInfo) {
      dispatch({
        type: "setSelectedState",
        payload: StudentInfo?.data[0]?.selected_state,
      });
      setValue("governorate", StudentInfo?.data[0]?.governorate);
      setValue("is_online", StudentInfo?.data[0]?.is_online);
      setValue("stage", StudentInfo?.data[0]?.stage);
      setValue("area", StudentInfo?.data[0]?.area);
      setValue("governorate", StudentInfo?.data[0]?.governorate);
      setValue("countries", StudentInfo?.data[0]?.country);
      setValue("group", String(StudentInfo?.data[0]?.group));
      setValue("chance", StudentInfo?.data[0]?.chances);
      StudentInfo?.data[0]?.is_student === 1
        ? setFormType("student")
        : setFormType("course");

      setValue(
        "gender",
        StudentInfo?.data[0]?.gender === 1 ? "male" : "female"
      );

      console.log(StudentInfo?.data[0]?.gender === 1 ? "male" : "female");

      setValues({
        ...StudentInfo?.data[0],
        active: StudentInfo?.data[0]?.active === 1 ? true : false,
        chance: StudentInfo?.data[0]?.chances,
      });
    }
  }, [StudentInfo, setValue]);

  useEffect(() => {
    if (watchGovernorate) {
      dispatch({
        type: "setCities",
        payload:
          state.states.find((city) => city.state === watchGovernorate)
            ?.cities || [],
      });
    }
  }, [watchGovernorate, state.states]);

  console.log(getValues(), watchPlace, errors, isValid);

  useEffect(() => {
    if (watchGovernorate) {
      dispatch({
        type: "setCities",
        payload: state.states?.filter(
          (city) => city.state === watchGovernorate
        )[0]?.cities,
      });
    }
    if (watchGovernorate === "" || watchGovernorate === 1) {
      dispatch({
        type: "setCities",
        payload: state.states?.filter((city) => city.id === 1)[0]?.cities,
      });
      setValue("governorate", "القاهرة");
      setValue("area", 1);
    }
  }, [watchGovernorate, state.governorate, state.states, setValue]);

  // useEffect(() => {
  //   if (StudentInfo?.data[0]?.is_student) {
  //     console.log();
  //     StudentInfo?.data[0]?.is_student === 1
  //       ? setFormType("student")
  //       : setFormType("course");
  //   }
  // }, [StudentInfo?.data, StudentInfo?.data.is_student]);

  // console.log(StudentInfo.data[0].is_student, formType, "jjj");

  const onSubmit = (data) => {
    const studentData = edit
      ? {
          ...data,
          profile_pic: data?.profile_pic[0] || null,
          magnetic_id: data?.magnetic_id || null,
          is_code: data.activation_code_radio === "havenot" ? 0 : 1,
          activation_code: data.activation_code ? data.activation_code : null,
          active: data?.active === true ? 1 : 0,
          gender: data?.gender === "male" ? 1 : 0,
          id: studentID,
        }
      : {
          ...data,
          fname: data?.fname && data?.fname?.trim(),
          sname: data?.sname && data?.sname?.trim(),
          lname: data?.lname && data?.lname?.trim(),
          profile_pic: data?.profile_pic[0],
          certificate_pic: data?.certificate_pic && data?.certificate_pic[0],
          school: data?.school || null,
          is_student: formType === "student" ? 1 : 0,
          governorate: formType === "student" ? data.governorate : null,
          area: formType === "student" ? data.area : null,
          stage: formType === "student" ? data.stage : null,
          is_online: formType === "student" ? data.is_online : null,
          activation_code: data.activation_code ? data.activation_code : null,
          gender:
            formType === "student" ? (data?.gender === "male" ? 1 : 0) : null,
          is_code: data.activation_code_radio === "havenot" ? 0 : 1,
        };

    if (edit) {
      dispatch({
        type: "setSubmitEditStudent",
        payload: {
          flag: "AddEditStudent",
          dependency: !state.submitEditStudent.dependency,
          data: studentData,
        },
      });
    } else {
      dispatch({
        type: "setSubmitLoading",
        payload: true,
      });
      async function postData() {
        try {
          const res = await axios.post(
            process.env.REACT_APP_REGISTER_API,
            studentData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          const data = await res.data;
          toast.success(data?.message);
          dispatch({
            type: "setSubmitLoading",
            payload: false,
          });
          !edit && navigate("/signin");
          reset();
        } catch (error) {
          if (error.response.data?.errors) {
            dispatch({
              type: "setErrors",
              payload: error.response.data?.errors,
            });
            dispatch({
              type: "setSubmitLoading",
              payload: false,
            });

            const errorMessages = Object.keys(error.response.data.errors).map(
              (field) => (
                <div key={field}>
                  {error.response.data.errors[field].map((errorMsg, index) => (
                    <p key={index}>{errorMsg}</p>
                  ))}
                </div>
              )
            );

            toast.error(<div>{errorMessages}</div>);
          } else {
            toast.error(<div>{error.response.data.message}</div>);
          }
        }
      }

      postData();
    }
  };

  useEffect(() => {
    if (watchPlace && !edit) {
      setValue("group", null);
    }
  }, [edit, setValue, watchPlace]);

  useEffect(() => {
    if (watchLevel && !edit) {
      setValue("group", null);
    }
  }, [edit, setValue, watchLevel]);

  if (studentInfoLoading || governorateLoading) return <Loader />;

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-0 w-full py-24 dark:bg-dark">
      <div
        className={`md:max-w-3/4 flex flex-col w-3/4 md:w-full md:px-5 ${
          studentInfoLoading && "hidden"
        }`}
      >
        {!edit && (
          <>
            <div className="welcome-message  flex flex-col items-center  justify-center mb-10">
              <Link className="h-[100px] w-auto text-4xl " to="/">
                <img className="h-full w-full " src={LogoLogin} alt="logo" />
              </Link>
              {formType === "course" && (
                <Link className="h-[100px] w-auto text-4xl " to="/">
                  <img
                    className="h-full w-full "
                    src={LogoLoginCourse}
                    alt="logo"
                  />
                </Link>
              )}
            </div>
          </>
        )}

        <HelmetTags
          title="Register | Nawar Academy"
          description="Create an account | Nawar Academy"
          index
        ></HelmetTags>

        {edit && (
          <HelmetTags
            title="Students | Nawar Academy"
            description="Create an account | Nawar Academy"
            index
          ></HelmetTags>
        )}

        <div className="flex w-full shadow-lg shadow-blue-200/50">
          <div className="flex-1 flex w-full flex-col items-center ">
            <form
              encType="multipart/form-data"
              method="post"
              onSubmit={handleSubmit(onSubmit)}
              className="w-full flex  flex-col items-start  justify-center gap-6 p-10"
            >
              {/* {!edit && (
                <div className="flex items-center md:flex-col w-full rounded-[50px] bg-main overflow-hidden">
                  <button
                    type="button"
                    className={`text-center py-4 flex-1 transition-all !rounded-[50px] !md:rounded-md md:w-full ${
                      formType === "student"
                        ? "bg-secondary text-white"
                        : " text-white"
                    }`}
                    onClick={() => setFormType("student")}
                  >
                    Nawar Academy Student
                  </button>
                  <button
                    type="button"
                    className={`text-center py-4 flex-1 transition-all !rounded-[50px] !md:rounded-md md:w-full ${
                      formType === "course"
                        ? "bg-secondary text-white"
                        : " text-white"
                    }`}
                    onClick={() => setFormType("course")}
                  >
                    Courses Student
                  </button>
                </div>
              )} */}

              {formType === "student" ? (
                <StudentForm
                  edit={edit}
                  register={register}
                  StudentEditErrors={StudentEditErrors}
                  state={state}
                  errors={errors}
                  getValues={getValues}
                  dispatch={dispatch}
                  StudentInfo={StudentInfo}
                  watchLevel={watchLevel}
                  watchPlace={watchPlace}
                  watchActivationCode={watchActivationCode}
                  StudentEditLoading={StudentEditLoading}
                  governorateLoading={governorateLoading}
                />
              ) : (
                <CourseStudentForm
                  register={register}
                  errors={errors}
                  state={state}
                  StudentEditErrors={StudentEditErrors}
                  dispatch={dispatch}
                  getValues={getValues}
                  edit={edit}
                />
              )}

              {/** Submit Button */}
              <button
                disabled={!isValid || state.submitLoading || StudentEditLoading}
                className="submit submit-login !bg-secondary !rounded-[50px] !md:rounded-md !h-[50px] max-w-60 mt-10 disabled:opacity-70 "
                type="submit"
              >
                {console.log(isValid)}
                {state.submitLoading || StudentEditLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : edit ? (
                  "Modify data"
                ) : (
                  "Create account"
                )}
              </button>
            </form>
            {!edit && (
              <div className="my-10 w-full text-center text-[14px]  ">
                Already have an account?
                <Link className=" font-bold" to="/signin">
                  Sign in now
                </Link>
              </div>
            )}
          </div>
          {/* <div className="flex-1 flex items-center justify-center flex-col gap-10  w-1/2 min-h-[400px] bg-[#00c1ff] ">
            <img src={TeacherImage} alt="" className="h-[600px]" />
            <h1 className="text-5xl text-white text-center font-bold">
              Welcome to Nawar Academy
            </h1>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Register;
