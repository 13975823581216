import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";

const ResultModalAnswer = () => {
  const { ID } = useParams();

  const [successData] = useAxios(
    `${process.env.REACT_APP_GET_STUDENT_MODELANSWER_API}${ID}`,
    "GET",
    "GET",
    ""
  );

  return (
    <section className="w-full min-h-screen flex flex-col gap-5 justify-center items-center py-10">
      {successData?.data?.map((item, index) => (
        <div className="border border-secondary rounded-3xl p-5 max-w-[500px]">
          <p>Question:{index + 1}</p>
          <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
          <div className="flex gap-5 w-full">
            {item?.student_answer && (
              <div className="categorySingleAnswer border border-red-500 p-2 rounded-3xl">
                <p className="text-red-500">Your Answer:</p>
                <div
                  dangerouslySetInnerHTML={{ __html: item.student_answer }}
                  className="text-red-500"
                />
              </div>
            )}
            {item?.correct_answer && (
              <div className="categorySingleAnswer border border-green p-2 rounded-3xl">
                <p className="text-green">Correct Answer:</p>
                <div
                  dangerouslySetInnerHTML={{ __html: item.correct_answer }}
                  className="text-green"
                />
              </div>
            )}
          </div>

          {item?.explanation && (
            <>
              <p>Explanation: </p>
              <div dangerouslySetInnerHTML={{ __html: item.explanation }} />
            </>
          )}
        </div>
      ))}
    </section>
  );
};

export default ResultModalAnswer;
