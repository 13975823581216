import { Loader } from "../../../MainComponents";

import React, { useRef } from "react";
import Single3D from "../../../MainComponents/Single3D";
import { useParams } from "react-router-dom";
import useFade from "../../../MainComponents/Hooks/useFade";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../MainComponents/HelmetTags";

export default function ViewThreeds() {
  const { chapterID } = useParams();
  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);

  /* Get 3D Data */
  const [ThreedData, ThreedErrors, Threedloading] = useAxios(
    `${process.env.REACT_APP_GET_THREED_API}${chapterID}`,
    "GET",
    "GET",
    ""
  );

  return (
    <section
      className={`h-full  w-full  px-20 py-20 md:px-5 dark:bg-dark dark:text-light space-y-5`}
    >
      <HelmetTags title="3D | Nawar Academy"></HelmetTags>{" "}
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear text-secondary ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          3D Models
        </h2>
      </div>
      {Threedloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit mb-[50px] py-5">
          {ThreedData?.data?.length === 0 ? (
            <p>No 3D</p>
          ) : (
            ThreedData?.data.map((Card) => {
              return <Single3D threed={Card} key={Card.id} />;
            })
          )}
        </div>
      )}
    </section>
  );
}
