import HelmetTags from "../../../MainComponents/HelmetTags";
import useTable from "../../../MainComponents/Hooks/useTable";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import { useEffect, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Quiz Performance Over Time",
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      min: 20,
      max: 100,
      ticks: {
        stepSize: 20, // Optional: Define steps for the Y-axis
      },
    },
  },
};

export default function StudentResults() {
  const Quizesstable = useTable("Quizess");
  const Homeworktable = useTable("Homework");
  const Examstable = useTable("Exams");
  const [runJoyride, setRunJoyride] = useState(false);
  const [steps, setSteps] = useState([]);

  const [quizData, quizError] = useAxios(
    process.env.REACT_APP_GET_STUDENT_RESULTS_QUIZ_API,
    "GET",
    "GET",
    ""
  );
  const [homeData, homeError] = useAxios(
    process.env.REACT_APP_GET_STUDENT_RESULTS_HOMEWORK_API,
    "GET",
    "GET",
    ""
  );
  const [examData, examError] = useAxios(
    process.env.REACT_APP_GET_STUDENT_RESULTS_EXAM_API,
    "GET",
    "GET",
    ""
  );

  const chartQuizData = {
    labels: quizData?.data?.map((item) => item.pecntage) || [],
    datasets: [
      {
        label: "Percentage",
        data: quizData?.data?.map((item) => item.pecntage) || [],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.4,
      },
    ],
  };
  const chartHomeworkData = {
    labels: homeData?.data?.map((item) => item.pecntage) || [],
    datasets: [
      {
        label: "Percentage",
        data: homeData?.data?.map((item) => item.pecntage) || [],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };
  const chartExamData = {
    labels: examData?.data?.map((item) => item.pecntage) || [],
    datasets: [
      {
        label: "Percentage",
        data: examData?.data?.map((item) => item.pecntage) || [],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };
  useEffect(() => {
    const isTourSeen = localStorage.getItem("tourSeen2");
    if (!isTourSeen) {
      setSteps([
        {
          target: ".quiz",
          content: "This is the header for quizzes.",
          disableBeacon: true,
          disableScrolling: true,
          spotlightClicks: true,
        },
        {
          target: ".homework",
          content: "This is the homework section.",
          spotlightClicks: true,
        },
        {
          target: ".exam",
          content: "This is the exam section.",
          spotlightClicks: true,
        },
      ]);
    }

    setRunJoyride(true);
  }, [quizData, homeData, examData]);
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (finishedStatuses.includes(status)) {
      // Save the tour status in localStorage
      localStorage.setItem("tourSeen2", "true");
      setRunJoyride(false); // Stop the tour
    }
  };

  return (
    <section className=" overflow-visible space-y-10  px-10 mt-16 md:px-0 dark:bg-dark dark:text-light ">
      <HelmetTags title="Results | Nawar Academy"></HelmetTags>
      <Joyride
        steps={steps}
        run={runJoyride}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClicks={false}
        styles={{
          options: {
            zIndex: 1000,
            arrowColor: "#fff",
            backgroundColor: "#333",
            textColor: "#fff",
            width: 300,
          },
        }}
        scrollOffset={60}
        callback={handleJoyrideCallback}
      />

      <div className="quiz mt-5 space-y-10">
        <h1 className="text-3xl text-secondary  ">Quizzes</h1>
        <div>{Quizesstable}</div>

        <h1 className="text-3xl text-secondary">Quiz Performance</h1>
        <Line data={chartQuizData} options={options} />
      </div>

      <div className="homework mt-5 space-y-10">
        <h1 className="text-3xl text-secondary  ">Homework</h1>
        <div>{Homeworktable}</div>

        <h1 className="text-3xl text-secondary">HomeWork Performance</h1>
        <Line data={chartHomeworkData} options={options} />
      </div>

      <div className="exam mt-5 space-y-10 ">
        <h1 className="text-3xl text-secondary  ">Exams</h1>
        <div>{Examstable}</div>

        <h1 className="text-3xl text-secondary">Exam Performance</h1>
        <Line data={chartExamData} options={options} />
      </div>
    </section>
  );
}
