import { useEffect, useRef, useState } from "react";
import {
  useAuthState,
  useAuthDispatch,
} from "../../../../MainComponents/GlobalContext.jsx";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Loader from "../../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const searchByMobileInput = useRef();
  const searchByParentMobileInput = useRef();
  const searchByIdInput = useRef();
  const [show, setshow] = useState();
  const [index, setIndex] = useState(null);
  const [submitSearch, setSubmitSearch] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  //! -------------- search studentt -------
  const [searchQuestionSuccess, searchQuestionErrors, searchloading] = useAxios(
    process.env.REACT_APP_SEARCH_STUDENT_API,
    "POST",
    submitSearch.flag,
    submitSearch.dependency,
    submitSearch.data,
    true
  );
  useEffect(() => {
    const data = searchQuestionSuccess?.data;

    if (Array.isArray(data)) {
      if (
        data.length > 1 &&
        index !== null &&
        index >= 0 &&
        index < data.length
      ) {
        navigate(`/admin/students/edit-student/${data[index]?.id}`);
      } else if (data.length === 1) {
        navigate(`/admin/students/edit-student/${data[0]?.id}`);
      } else {
        console.warn("Array is empty or index is out of range.");
      }
    } else if (data && data.id) {
      navigate(`/admin/students/edit-student/${data.id}`);
    } else {
      console.warn("Data is invalid or undefined.");
    }
  }, [navigate, searchQuestionSuccess, index]);

  function handleSearch(method, value) {
    setSubmitSearch({
      flag: "searchStudent",
      dependency: !submitSearch.dependency,
      data: { method, value },
    });
  }

  function handleKeyPress(e, method, inputRef) {
    if (e.key === "Enter") {
      handleSearch(method, inputRef.current.value);
    }
  }

  if (
    !AuthState.userData?.admin?.roles[0]?.name === "superadmin" &&
    !AuthState.userData?.admin?.permissions?.some((permission) =>
      permission.name.startsWith("post")
    )
  ) {
    return (
      <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
        <HelmetTags title="Home | Nawar Academy"></HelmetTags>
        <h2 className="w-full text-4xl font-bold md:text-center mb-12">Home</h2>
      </section>
    );
  }
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20  dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Home | Nawar Academy"></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center mb-12">Home</h2>
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("student_search")
        )) && (
        <>
          <div className="flex flex-col gap-2 items-center w-1/2 md:w-full m-auto mb-12">
            <input
              className="signin-inputs signin-input w-full "
              type="number"
              min={1}
              id="code_id"
              placeholder="Search By Mobile"
              name="code_id"
              autoComplete="on"
              ref={searchByMobileInput}
              onKeyDown={(e) =>
                handleKeyPress(e, "mobile", searchByMobileInput)
              }
            />
            <button
              className=" submit mt-6 !w-fit px-4 !md:w-full min-w-[200px]"
              type="submit"
              disabled={searchloading}
              onClick={() =>
                handleSearch("mobile", searchByMobileInput.current.value)
              }
            >
              {searchloading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>Search By Mobile</>
              )}
            </button>
          </div>
          <div className="flex flex-col gap-2 items-center w-1/2 md:w-full m-auto mb-12">
            {searchQuestionSuccess?.data?.length > 1 && (
              <div className="flex flex-wrap gap-5">
                {searchQuestionSuccess?.data?.map((item, index) => (
                  <div
                    className="bg-logoYellow px-3 py-4 cursor-pointer text-black"
                    key={item.id}
                    onClick={() => setIndex(index)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            )}
            <input
              className="signin-inputs signin-input w-full "
              type="number"
              min={1}
              id="code_id"
              placeholder="Search By Parent Mobile"
              name="code_id"
              autoComplete="on"
              ref={searchByParentMobileInput}
              onKeyDown={(e) =>
                handleKeyPress(e, "mobilep2", searchByParentMobileInput)
              }
            />
            <button
              className=" submit mt-6 !w-fit px-4 !md:w-full min-w-[200px] "
              type="submit"
              disabled={searchloading}
              onClick={() =>
                handleSearch(
                  "mobilep2",
                  searchByParentMobileInput.current.value
                )
              }
            >
              {searchloading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>Search By Parent Mobile</>
              )}
            </button>
          </div>
          <div className="flex flex-col gap-2 items-center w-1/2 md:w-full m-auto mb-12">
            <input
              className="signin-inputs signin-input w-full "
              type="number"
              min={1}
              id="parent_number"
              placeholder="Search By ID"
              name="parent_number"
              autoComplete="on"
              ref={searchByIdInput}
              onKeyDown={(e) => handleKeyPress(e, "id", searchByIdInput)}
            />
            <button
              className=" submit mt-6 !w-fit px-4 !md:w-full min-w-[200px] "
              type="submit"
              disabled={searchloading}
              onClick={() => handleSearch("id", searchByIdInput.current.value)}
            >
              {searchloading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>Search By ID</>
              )}
            </button>
          </div>
        </>
      )}
    </section>
  );
}

export default Home;
