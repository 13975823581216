import { Loader } from "../../../MainComponents";
import HelmetTags from "../../../MainComponents/HelmetTags";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import React, { useRef, useState } from "react";
import SingleChapter from "../../../MainComponents/SingleChapter";
import useFade from "../../../MainComponents/Hooks/useFade";

export default function Threed() {
  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);

  const [allLecturesData, allLecturesErrors, allLecturesLoading] = useAxios(
    process.env.REACT_APP_STUDENT_LECTURES_API,
    "GET",
    "GET"
  );

  return (
    <section
      className={`h-full  w-full  px-20 py-20 md:px-5 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="3D | Nawar Academy"></HelmetTags>{" "}
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-32 text-center text-5xl font-bold transition-all duration-300 ease-linear text-secondary ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          3D Models
        </h2>
      </div>
      {allLecturesLoading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit mb-[50px]">
          {allLecturesData?.data?.length === 0 ? (
            <p>No Chapters</p>
          ) : (
            allLecturesData?.data
              ?.filter((lect) => +lect.is_course === 0)
              ?.filter((chap) => chap.is_chapter === 1)

              .map((Card) => {
                return <SingleChapter threed chapter={Card} key={Card.key} />;
              })
          )}
        </div>
      )}
    </section>
  );
}
