import { DatePicker, Modal } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Spin } from "antd";
import toast from "react-hot-toast";
const { RangePicker } = DatePicker;

export default function AnalyticesWrongStudents() {
  const [date, setDate] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setselectedStudent] = useState(null);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    depdency: false,
    data: {},
  });

  const [successData, errorData, loadingSuccess] = useAxios(
    process.env.REACT_APP_ANALYTICS_TOP_WRONG_STUDENTS_API,
    "POST",
    submitAdd.flag,
    submitAdd.depdency,
    submitAdd.data,
    true
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors , isValid },

  } = useForm();

  const handleDateChange = (dates, dateString) => {
    if (dates) {
      const formattedDates = dates.map((date) => date.format("YYYY-MM-DD")); 
      setDate(formattedDates);
      setValue("dateRange", formattedDates); 
    }
  };

  const onSubmit = (data) => {
    if (!date.length) {
      toast.error("Please select a valid date range.");
      return;
    }

    const [from, to] = date;

    setSubmitAdd({
      flag: "Change Table",
      depdency: !submitAdd.depdency,
      data: {
        from,
        to,
        stage: data.stage,
        limit: data.limit,
      },
    });
  };

  const showModal = (question) => {
    setselectedStudent(question);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="px-10 py-10 flex flex-col gap-5">
      {/* Form Section */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-1/2 sm:w-full space-y-5 rounded-3xl border border-secondary shadow-2xl p-5"
      >
        {/* Date Range Picker */}
        <RangePicker
          className="signin-inputs"
          onChange={handleDateChange}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
        {errors.dateRange && (
          <p className="text-red-500">{errors.dateRange.message}</p>
        )}

        {/* Stage Selector */}
        <select
          className="signin-inputs"
          {...register("stage", { required: "Stage selection is required" })}
        >
          <option value="">Select a stage</option>
          <option value="1">Stage 1</option>
          <option value="2">Stage 2</option>
          <option value="3">Stage 3</option>
        </select>
        {errors.stage && <p className="text-red-500">{errors.stage.message}</p>}

        {/* Limit Input */}
        <input
          type="number"
          min={1}
          max={50}
          className="signin-inputs"
          placeholder="Limit of questions"
          {...register("limit", {
            required: "Limit is required",
            min: { value: 1, message: "Minimum limit is 1" },
            max: { value: 50, message: "Maximum limit is 50" },
          })}
        />
        {errors.limit && <p className="text-red-500">{errors.limit.message}</p>}

        {/* Submit Button */}
        <button type="submit" className="submit" disabled={loadingSuccess || !isValid}>
          {loadingSuccess ?      <Spin  /> : "Submit" }
        </button>
      </form>

  
      <div className="flex gap-5 flex-wrap">
        {loadingSuccess ? <Spin size="large" /> : successData &&
          successData.data.map((student, index) => (
            <div className="relative w-24 h-24" key={student.id}>
              <button
                onClick={() => showModal(student)}
                className={`w-full h-full rounded-2xl text-white text-3xl flex items-center justify-center cursor-pointer border border-black bg-secondary`}
              >
                {index + 1}
              </button>
            </div>
          )) }
        
      </div>

      {/* Modal Section */}
      <Modal
        title="Student Detail"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <button key="cancel" onClick={handleCancel} className="submit">
            Cancel
          </button>,
        ]}
      >
        {selectedStudent ? (
          <div className="w-full flex flex-col gap-5 items-center justify-center">
            <img src={selectedStudent?.profile_pic} alt={selectedStudent?.student_name} className="w-[300px] h-[300px] rounded-full"/>
            <p className="text-red-500 text-3xl">
              Total Wrong: {selectedStudent?.wrong_count}
            </p>
            <p className="text-3xl">Student Name: {selectedStudent?.student_name}</p>
            <p className="text-3xl">Mobile: {selectedStudent?.mobile}</p>
            <p className="text-3xl">Parent Mobile: {selectedStudent?.parent_mobile}</p>
          </div>
        ) : (
          <Spin size="large" />
        )}
      </Modal>
    </div>
  );
}
