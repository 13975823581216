import { Loader } from "../../../../MainComponents";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import React, { useState } from "react";
import Single3D from "../../../../MainComponents/Single3D";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
export default function AdminViewThreed() {
  const { chapterID } = useParams();
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [reFetch, setReFetch] = useState(false);

  /* Get 3D Data */
  const [ThreedData, ThreedErrors, Threedloading] = useAxios(
    `${process.env.REACT_APP_ADMIN_THREED_TAB_API}${chapterID}`,
    "GET",
    "GET",
    reFetch
  );

  return (
    <section
      className={`h-full  w-full  px-20 py-20 md:px-5 dark:bg-dark dark:text-light space-y-5`}
    >
      <HelmetTags title="3D | Nawar Academy"></HelmetTags>{" "}
      <div className="mb-20 flex flex-col items-end gap-20 capitalize">
        <h2 className="w-full text-4xl font-bold md:text-center">3D</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("3d_add")
          )) && (
          <div className="flex w-full gap-10 md:flex-col md:justify-evenly">
            <button
              onClick={() =>
                navigate(`/admin/threes/threed/add-threed/${chapterID}`)
              }
              className="mr-4 rounded-xl border-2 border-secondary order-2 bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add 3D
            </button>
          </div>
        )}
      </div>
      {Threedloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit mb-[50px] py-5">
          {ThreedData?.data?.length === 0 ? (
            <p>No 3D</p>
          ) : (
            ThreedData?.data.map((Card) => {
              return (
                <Single3D
                  reFetch={reFetch}
                  setReFetch={setReFetch}
                  admin
                  threed={Card}
                  key={Card.id}
                />
              );
            })
          )}
        </div>
      )}
    </section>
  );
}
